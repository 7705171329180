<template>
  <div class="px-4 mb-40 pt-20">
      <div class="flex justify-center">
        <h1 class="text-4xl max-w-screen-sm text-center my-8">Lieux de pêche</h1>
      </div>
      <div class="space-y-8">
        <div data-aos="fade-up" class="">
          <h2 class="text-2xl my-2 underline decoration-12 decoration-blue-400">Embouchure du Grand Rhône : </h2>
          <ul>
            <li>– Au large nous pêchons les thons au broumé ou à la traîne </li>
            <li>– Plus au bord ce sera la pêche de la liche au vif ou au leurre</li>
            <li>– La pêche des bonites aux leurres se fera dans les fonds de 50m</li>
          </ul>
        </div>
        <div data-aos="fade-up" class="">
          <h2 class="text-2xl my-2 underline decoration-12 decoration-blue-400">Golf de Fos : </h2>
          <ul>
            <li>– Pêche du maquereau au broumé sur le spot de la balancelle</li>
            <li>– Pêche de la daurade royale le long des plages et du littoral</li>
            <li>– Pêche de la bonite à la traîne légère</li>
          </ul>
        </div>
        <div data-aos="fade-up" class="">
          <h2 class="text-2xl my-2 underline decoration-12 decoration-blue-400">Côte Bleue (entre Carro et Marseille) : </h2>
          <ul>
            <li>– Pêche de la bonite aux leurres</li>
            <li>– Pêche de la daurade à la pierre</li>
            <li>– Pêche du denti et des pagres au large</li>
            <li>– Pêche du Pageot</li>
          </ul>
        </div>
        <div data-aos="fade-up" class="">
          <h2 class="text-2xl my-2 underline decoration-12 decoration-blue-400">Île du Frioul : </h2>
          <ul>
            <li>– Pêche de la bonite aux leurres</li>
            <li>– Pêche de la daurade à la pierre</li>
            <li>– Pêche du denti et des pagres</li>
            <li>– Pêche du pageot</li>
          </ul>
        </div>
        <div data-aos="fade-up" class="">
          <h2 class="text-2xl my-2 underline decoration-12 decoration-blue-400">Île du Planier : </h2>
          <ul>
            <li>– Pêche de la bonite aux leurres</li>
            <li>– Pêche de la daurade à la pierre</li>
            <li>– Pêche du denti et des pagres</li>
            <li>– Pêche du pageot</li>
            <li>– Pêche de la coryphène</li>
          </ul>
        </div>
        <div data-aos="fade-up" class="">
          <h2 class="text-2xl my-2 underline decoration-12 decoration-blue-400">Etang de Berre (magnifique étang d’eau salé, le plus grand d’Europe) :</h2>
          <ul>
            <li>– Pêche du loup aux leurres et à la traîne</li>
            <li>– Pêche de la daurade royale aux appâts naturels</li>
          </ul>
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
</style>